import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { UserService } from '../services/user.service';
import { ServerService } from '../services/server.service';
import { LlRolesService } from './ll-roles.service';
import { LlModelsService } from './ll-models.service';
import { LlCompaniesService } from './ll-companies.service';
import { LlPermissionsService } from './ll-permissions.service';
import { getDate_CCYYMMDD } from "./utility-date";
import { getTime_HHMM24 } from "./utility-date";

@Directive()
@Injectable({
  providedIn: 'root'
})

export class LlInfoRequestsService {
  
  @Output() renewQuestion: EventEmitter<number> = new EventEmitter();
  @Output() renewForm: EventEmitter<any> = new EventEmitter();
  @Output() renewVisits: EventEmitter<any> = new EventEmitter();
  @Output() changeDocDisplay = new EventEmitter<any>();
  @Output() viewEditQuestion: EventEmitter<any> = new EventEmitter();

  constructor(public ServerService: ServerService,
    private UserService: UserService,
    private LlRolesService: LlRolesService,
    private LlModelsService: LlModelsService,
    private LlCompaniesService: LlCompaniesService,
    private LlPermissionsService: LlPermissionsService,
  ) { }  

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    CONTACTID: 0,
    COMPANYID: 0,
    COMPANYID_ACTIVE:0,
    STUDYID_SYS: 0,
    CONTACTROLEID: 0,
    SYSTEMROLEID: 0,
    QUESTIONSTAGEID: 0,
    LOANMODELID: 0,
    SUBJECTVISITID: 0,
    SUBJECTVISITFORMID: 0,
    STUDYVISITID:0,
    STUDYFORMID: 0,
    STUDYQUESTIONID: 0,
    STUDYQUESTIONID_Benchmark:0,
    EVENT_DATE_LOCAL: "",
    EVENT_TIME_LOCAL: "",
    STUDYUPLOADID: 0,
    QUESTION_LOCATION:'',
    ACTION: "",
    FREQUENCY: "",
    EVERY: "",
    DAY: "",
    STARTDATE: "",
    EVENTCOUNT: "",
    FILEDATA: "",
    FILESIZE:0,
    ORIGIONALFILENAME:"",
    ORIGIONALFILEEXTENTION: "",
    UPLOADER: "",
    DOCUMENTDATE: "",
    DOCUMENTTIME: "",
    REMOVEREASON: "",
    QUESTIONRESULT_SPONSOR: "",
    QUESTIONRESULT_USER: "",
    INTENTIONALLYLEFTBLANKYESNO: "",
    MONTHEND_NUM: 0,
    RECORDLIST: [],
    TARGETELEMENT_LEFT:'',
    TARGETELEMENT_RIGHT: '',
   
  }
    
  bundle_local = {
    activeSubjectVisitId: 0,
    activeSubjectVisitFormId: 0,
    activeSubjectQuestionId: 0,
    activeStudyUploadId: 0,
    activeQuestionTabId: -100,
    docDisplay: "list",
    acceptedExts:"",
    vars: this.vars,
    ServerService: this.ServerService,
    LlPermissionsService: this.LlPermissionsService,
    LlRolesService: this.LlRolesService,
    renewVisits: this.renewVisits,
    renewForm: this.renewForm,
    renewQuestion: this.renewQuestion,
    subjectVisits: [],
    subjectVisitForms: [],
    subjectQuestions:[],
    questionChoices: [],
    uploads: [],
    elements_graph01: [],
    roleQCStages:[],
    //data_graph01: [],
    inBundle: [],    
    benchmarkValues:[],
  }
   
  NG_LL_infoReq_subjectVisits_get(callback01?: Function, inBundle?) {

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectVisits_get"
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.LOANMODELID = this.LlModelsService.getActiveId();
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId()

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      bundle_local.subjectVisits = arr;

      if (arr.length > 0) {
        bundle_local.vars.SUBJECTVISITID = arr[0].SUBJECTVISITID;
      }

      callback01(arr, inBundle);    

    }

  }

  getExistingSubjectVisits(callback01?: Function, inBundle?, obj?) {
    if (obj != null) {
      callback01(this.bundle_local.subjectVisits, inBundle, obj);
    } else {
      callback01(this.bundle_local.subjectVisits, inBundle);
    }
    
  }

  setActiveSubjectVisitId(subjectVisitId) {
    this.bundle_local.vars.SUBJECTVISITID = subjectVisitId;
  }

  getActiveSubjectVisitId() {  
    return this.bundle_local.vars.SUBJECTVISITID;   
  }

  getActiveSubjectVisit(callback01?: Function, bundle?) {

    for (let i = 0; i < this.bundle_local.subjectVisits.length; i++) {

      if (this.bundle_local.subjectVisits[i].SUBJECTVISITID == this.bundle_local.vars.SUBJECTVISITID) {

        if (callback01 == null) {
          return this.bundle_local.subjectVisits[i]
        } else {
          callback01(this.bundle_local.subjectVisits[i], bundle);
        }

      }
    }

  }

  setActiveQuestionTab(ID) {
    this.bundle_local.activeQuestionTabId = ID;
  }

  NG_LL_infoReq_studyVisitChoices_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_studyVisitChoices_get"

    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId()

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)  

      callback01(arr, inBundle);

    }

  }

  NG_LL_infoReq_subjectVisits_add(inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectVisits_add"
    this.bundle_local.vars.LOANMODELID = this.LlModelsService.getActiveId();
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();
    this.bundle_local.vars.EVENT_DATE_LOCAL = getDate_CCYYMMDD();
    this.bundle_local.vars.EVENT_TIME_LOCAL = getTime_HHMM24();
       
    this.bundle_local.vars.FREQUENCY = inBundle.FREQUENCY;
    this.bundle_local.vars.EVERY = inBundle.EVERY;
    this.bundle_local.vars.DAY = inBundle.DAY;
    this.bundle_local.vars.STARTDATE = inBundle.STARTDATE;
    this.bundle_local.vars.EVENTCOUNT = inBundle.EVENTCOUNT;
    this.bundle_local.vars.STUDYVISITID = inBundle.STUDYVISITID;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)  

         
      bundle_local.renewVisits.emit(true);

      

    }

  }

  NG_LL_infoReq_subjectVisits_remove(recordList, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectVisits_remove"
    this.bundle_local.vars.LOANMODELID = this.LlModelsService.getActiveId();
    this.bundle_local.vars.EVENT_DATE_LOCAL = getDate_CCYYMMDD();
    this.bundle_local.vars.EVENT_TIME_LOCAL = getTime_HHMM24();    
    this.bundle_local.vars.RECORDLIST = recordList;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      bundle_local.renewVisits.emit(true);

    }

  }

  NG_LL_infoReq_subjectVisitForms_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectVisitForms_get"

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)  

      if (arr.length > 0) {
        bundle_local.vars.SUBJECTVISITFORMID = arr[0].SUBJECTVISITFORMID;
      }

      bundle_local.subjectVisitForms = arr;

      callback01(arr, inBundle);

    }

  }

  setActiveSubjectVisitFormId (subjectVisitFormId) {
    this.bundle_local.vars.SUBJECTVISITFORMID = subjectVisitFormId;

    var form = this.getActiveSubjectVisitForm();

    this.bundle_local.vars.STUDYFORMID = form.STUDYFORMID;
  }

  getActiveSubjectVisitFormId() {
    return this.bundle_local.vars.SUBJECTVISITFORMID;
  }

  setActiveSubjectVisitFormId_company(subjectVisitFormId) {
    //console.log("setActiveSubjectVisitFormId_company")
    
    this.bundle_local.vars.SUBJECTVISITFORMID = subjectVisitFormId;
  }

  getActiveSubjectVisitForm(callback01?: Function, bundle?) {

    for (let i = 0; i < this.bundle_local.subjectVisitForms.length; i++) {

      if (this.bundle_local.subjectVisitForms[i].SUBJECTVISITFORMID == this.bundle_local.vars.SUBJECTVISITFORMID) {

        if (callback01 == null) {
          return this.bundle_local.subjectVisitForms[i]
        } else {
          callback01(this.bundle_local.subjectVisitForms[i], bundle);
        }

      }
    }
  }

  setStudyFormId(STUDYFORMID) {
    this.bundle_local.vars.STUDYFORMID = STUDYFORMID;
  }

  NG_LL_infoReq_subjectQuestions_get(callback01: Function, inBundle, QUESTION_LOCATION) {

    var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectQuestions_get";
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.QUESTION_LOCATION = QUESTION_LOCATION;
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId()

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {      

      //console.log(arr)

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].QUESTIONINPUTTYPE != 'HEADER') {
          bundle_local.vars.STUDYQUESTIONID = arr[0].STUDYQUESTIONID;
          break
        }
      }

      bundle_local.subjectQuestions = arr;

      callback01(arr, inBundle);

    }

  }

  getFormQuestions() {
    return this.bundle_local.subjectQuestions
  }

  setActiveStudyQuestionId(STUDYQUESTIONID) {
    this.bundle_local.vars.STUDYQUESTIONID = STUDYQUESTIONID;
  }

  getActiveStudyQuestionId() {
    return this.bundle_local.vars.STUDYQUESTIONID;
  }

  getActiveQuestion(callback01?: Function, bundle?) {   

    for (let i = 0; i < this.bundle_local.subjectQuestions.length; i++) {

      if (this.bundle_local.subjectQuestions[i].STUDYQUESTIONID == this.bundle_local.vars.STUDYQUESTIONID) {

         callback01(this.bundle_local.subjectQuestions[i], bundle);
        
      }
    }
  }

  getSelectStudyQuestion(STUDYQUESTIONID, callback01?: Function, bundle?) {

    for (let i = 0; i < this.bundle_local.subjectQuestions.length; i++) {

      if (this.bundle_local.subjectQuestions[i].STUDYQUESTIONID == STUDYQUESTIONID) {

        this.bundle_local.subjectQuestions[i].dataEntry = false;

        if (this.bundle_local.subjectQuestions[i].POSTCLINICALYESNO.toLowerCase() == 'no' && this.LlPermissionsService.check("questionDataEntry") == true) {
          this.bundle_local.subjectQuestions[i].dataEntry = true;
        }

        if (this.bundle_local.subjectQuestions[i].POSTCLINICALYESNO.toLowerCase() == 'yes' && this.LlPermissionsService.check("questionPostClinEnt") == true) {
          this.bundle_local.subjectQuestions[i].dataEntry = true;
        }

        if (this.bundle_local.subjectQuestions[i].QUESTIONINPUTTYPE == "UPLOAD_METH02") {

          if (this.LlPermissionsService.check("download")
            || this.LlPermissionsService.check("editUpload")
            || this.LlPermissionsService.check("remove")
            || this.LlPermissionsService.check("upload")) {
            this.bundle_local.subjectQuestions[i].dataEntry = true;
          }

        }

       ///questionStageId	questionStageName
        ///1	Not Entered
        ///2	Entered
        ///3	Verified
        ///5	Reviewed
        ///6	Locked
       /// 20	Signed

        if (this.bundle_local.subjectQuestions[i].READONLYYESNO.toLowerCase() == 'yes'
          || this.bundle_local.subjectQuestions[i].QUESTIONSTAGEID == 6 //locked
          || this.bundle_local.subjectQuestions[i].QUESTIONSTAGEID == 20 //signed
          || this.bundle_local.subjectQuestions[i].QUESTIONSTAGEID == 18 //deleted

        ) {
          this.bundle_local.subjectQuestions[i].dataEntry = false;
        }

        if (this.bundle_local.subjectQuestions[i].QUESTIONINPUTTYPE == "FTX_CALC") {
          this.bundle_local.subjectQuestions[i].dataEntry = false;
        }


        if (callback01 == null) {
          return this.bundle_local.subjectQuestions[i];
        } else {
          callback01(this.bundle_local.subjectQuestions[i], bundle);
        }
      }
    }
  }

  NG_LL_infoReq_subjectQuestion_choices_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectQuestion_choices_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.questionChoices = arr;      

      callback01(inBundle)
    }
  }

  getQuestionChoices(callback01: Function, inBundle) {

    var choices = [];

    for (let i = 0; i < this.bundle_local.questionChoices.length; i++) {

      var indx = choices.length;

      if (this.bundle_local.questionChoices[i].STUDYQUESTIONID == inBundle.STUDYQUESTIONID) {
        ///this.bundle_local.questionChoices[i].SELECTED = false;
        choices[indx] = this.bundle_local.questionChoices[i];
      }
    }

    callback01(choices, inBundle)
    
  }

  NG_LL_infoReq_subjectQuestions_updateValue(TYPE, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectQuestions_updateValue";

    this.bundle_local.vars.QUESTIONRESULT_USER = inBundle.QUESTIONRESULT_USER;
    this.bundle_local.vars.EVENT_DATE_LOCAL = getDate_CCYYMMDD();
    this.bundle_local.vars.EVENT_TIME_LOCAL = getTime_HHMM24();
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId()

    if (TYPE.toLowerCase() == "blank") {
      this.bundle_local.vars.INTENTIONALLYLEFTBLANKYESNO = inBundle.INTENTIONALLYLEFTBLANKYESNO;
    }

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      ///renewVisits
      for (let i = 0; i < bundle_local.subjectVisits.length; i++) {

        if (bundle_local.subjectVisits[i].SUBJECTVISITID == bundle_local.vars.SUBJECTVISITID) {
          bundle_local.subjectVisits[i].CIRCLEIMG_VISIT = arr[0].CIRCLEIMG_VISIT;
          //bundle_local.subjectVisits[i].CIRCLEIMG_VISIT = 'assets/statusCircle_query.png';////TESTING

          break
        }
      }

      //renewForm
       for (let i = 0; i < bundle_local.subjectVisitForms.length; i++) {
        if (bundle_local.subjectVisitForms[i].SUBJECTVISITFORMID == bundle_local.vars.SUBJECTVISITFORMID) {        
          bundle_local.subjectVisitForms[i].CIRCLEIMG_FORM = arr[0].CIRCLEIMG_FORM;
          //bundle_local.subjectVisitForms[i].CIRCLEIMG_FORM = 'assets/statusCircle_query.png'; ////TESTING
          break
        }
       }

      let renewform = false;

      for (let i = 0; i < bundle_local.subjectQuestions.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (bundle_local.subjectQuestions[i].STUDYQUESTIONID == arr[j].STUDYQUESTIONID && bundle_local.subjectQuestions[i].QUESTIONREQUIRED != arr[j].QUESTIONREQUIRED) {
            renewform = true;
          }
        }
      }
      
      if (bundle_local.subjectQuestions.length != arr.length) {
        renewform = true;
      }

      ///replace only after REQUIRED logic above
      bundle_local.subjectQuestions = arr;

      ///if the new count on records does not match the old...so some have been added or removed
      if (renewform == true) {

        //renew the entire form
        bundle_local.renewForm.emit();

      } else {

        ///renew each question 
        for (let i = 0; i < bundle_local.subjectQuestions.length; i++) {
          bundle_local.renewQuestion.emit(bundle_local.subjectQuestions[i].STUDYQUESTIONID);
        }
      }     
    }

  }

  NG_LL_infoReq_uploads_form_get(callback01?: Function, inBundle?) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_uploads_form_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      bundle_local.uploads = arr;

      ///console.log(arr)

      callback01(inBundle);
    }
  }

  setActiveStudyUploadId(STUDYUPLOADID) {
    this.bundle_local.vars.STUDYUPLOADID = STUDYUPLOADID;
  }

  getActiveStudyUploadId() {
    return this.bundle_local.vars.STUDYUPLOADID;
  }

  getActiveStudyUpload() {

    for (let i = 0; i < this.bundle_local.uploads.length; i++) {

      if (this.bundle_local.uploads[i].STUDYUPLOADID == this.bundle_local.vars.STUDYUPLOADID) {

        this.bundle_local.uploads[i].dataEntry = false;

        if (this.LlPermissionsService.check("editUpload")) {
          this.bundle_local.uploads[i].dataEntry = true;
        }

        return this.bundle_local.uploads[i];
      }
    }
  }

  getQuestionUploads(STUDYQUESTIONID, callback01?, bundle?, obj?) {

    ///console.log("STUDYQUESTIONID: " + STUDYQUESTIONID)

    var arr = [];

    for (let i = 0; i < this.bundle_local.uploads.length; i++) {

      if (this.bundle_local.uploads[i].STUDYQUESTIONID == STUDYQUESTIONID) {
        this.bundle_local.uploads[i].VIEWABLE = false;

        if (this.bundle_local.uploads[i].ORIGIONALFILEEXTENTION == "pdf" || this.bundle_local.uploads[i].ORIGIONALFILEEXTENTION == "txt") {
          this.bundle_local.uploads[i].VIEWABLE = true;
        }

        arr[arr.length] = this.bundle_local.uploads[i];
      }
    }

    if (callback01 != null) {
      callback01(arr, bundle, obj)
    } else {
      return arr;
    }

  }
    
  NG_LL_infoReq_uploads_acceptedExtentions_get(callback01?: Function, inBundle?) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_infoReq_uploads_acceptedExtentions_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      bundle_local.acceptedExts = arr[0].EXTS

      ///console.log(bundle_local.acceptedExts)

      callback01(inBundle);

    }
  }

  getacceptedExts() {
    return this.bundle_local.acceptedExts
  }

  getUploadPreview(type, inBundle, callback01?) {

    //console.log("openDoc")
    this.bundle_local.inBundle = inBundle;

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();
    
    this.bundle_local.vars.ac = "NG_uploads_preview";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.STUDYID_SYS = 0;
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.STUDYUPLOADID = this.bundle_local.vars.STUDYUPLOADID;

    if (this.bundle_local.vars.STUDYUPLOADID != 0) {
      this.ServerService.callserver(srvReturn, this.bundle_local, "show");
    }


    function srvReturn(arr, bundle_local) {
      callback01(arr, bundle_local.inBundle);
    }
  }

  NG_LL_infoReq_uploads_download() {
    this.bundle_local.vars.ac = "NG_LL_infoReq_uploads_download";

    if (this.bundle_local.vars.STUDYUPLOADID != 0) {
      this.ServerService.callserver(srvReturn, this.bundle_local, "show");
    }


    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      let name = arr[0].ORIGIONALFILENAME + "." + arr[0].ORIGIONALFILEEXTENTION.toLowerCase()
      let b64Data = arr[0].UPLOADCONTENT;
      let blob = base64ToBlob02(b64Data, arr[0].MIMETYPE, 512)

      ///if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
      //if (window.navigator ) { // IE workaround
        //window.navigator.msSaveOrOpenBlob(blob, name);

      //} else { //not IE

        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = name;
        a.click();
        URL.revokeObjectURL(objectUrl);

      //}

      function base64ToBlob02(b64Data, contentType, sliceSize) {
        b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);

          let byteNumbers = new Array(slice.length);

          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          let byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      }


    }
  }

  NG_LL_infoReq_uploads_add(callback01: Function, inBundle, file, value) {

    let nameParts = file.name.split(".");

    let extLoc = nameParts.length - 1;
    let fileName = ""

    //console.log("extLoc:" + extLoc)
    
    this.bundle_local.vars.FILESIZE = file.size;

    //console.log("FILESIZE: "+ this.bundle_local.vars.FILESIZE);

    this.bundle_local.vars.ac = "NG_LL_infoReq_uploads_add";
        
    for (let i = 0; i < extLoc; i++) {
      if (fileName == '') {
        fileName = nameParts[i];
      } else {
        fileName = fileName + "_" + nameParts[i];
      }
    }

    this.bundle_local.vars.ORIGIONALFILENAME = fileName;

    //console.log("fileName:" +this.bundle_local.vars.ORIGIONALFILENAME)

    this.bundle_local.vars.ORIGIONALFILEEXTENTION = nameParts[extLoc];    
    this.bundle_local.vars.FILEDATA = value;
    this.bundle_local.vars.EVENT_DATE_LOCAL = getDate_CCYYMMDD();
    this.bundle_local.vars.EVENT_TIME_LOCAL = getTime_HHMM24();    

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(uploads, bundle_local) {

      //console.log(uploads)

      bundle_local.uploads = uploads;
      bundle_local.vars.FILEDATA = '';

      bundle_local.vars.ac = "NG_LL_infoReq_subjectQuestionFormVisit_QCStages_get";

      bundle_local.ServerService.callserver(srvReturn2, bundle_local, "show");

      function srvReturn2(arr, bundle_local) {

        //console.log(arr)

        ///renewVisits
        for (let i = 0; i < bundle_local.subjectVisits.length; i++) {

          if (bundle_local.subjectVisits[i].SUBJECTVISITID == bundle_local.vars.SUBJECTVISITID) {
            bundle_local.subjectVisits[i].CIRCLEIMG_VISIT = arr[0].CIRCLEIMG_VISIT;
            break
          }
        }

        //renewForm
        for (let i = 0; i < bundle_local.subjectVisitForms.length; i++) {
          if (bundle_local.subjectVisitForms[i].SUBJECTVISITFORMID == bundle_local.vars.SUBJECTVISITFORMID) {
            bundle_local.subjectVisitForms[i].CIRCLEIMG_FORM = arr[0].CIRCLEIMG_FORM;
            break
          }
        }
         
        ///renewQuestion
        for (let i = 0; i < bundle_local.subjectQuestions.length; i++) {

          if (bundle_local.subjectQuestions[i].STUDYQUESTIONID == bundle_local.vars.STUDYQUESTIONID) {           
            bundle_local.subjectQuestions[i].CIRCLEIMG_QUESTION = arr[0].CIRCLEIMG_QUESTION;
            bundle_local.renewQuestion.emit(bundle_local.vars.STUDYQUESTIONID);
            break
          }
        }

        callback01(inBundle);
      }

    }
  }

  NG_LL_infoReq_uploads_remove(callback01: Function, REMOVEREASON, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_uploads_remove";
    this.bundle_local.vars.REMOVEREASON = REMOVEREASON;
    this.bundle_local.vars.EVENT_DATE_LOCAL = getDate_CCYYMMDD();
    this.bundle_local.vars.EVENT_TIME_LOCAL = getTime_HHMM24();

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.uploads = arr;

      bundle_local.vars.ac = "NG_LL_infoReq_subjectQuestionFormVisit_QCStages_get";

      bundle_local.ServerService.callserver(srvReturn2, bundle_local, "show");

      function srvReturn2(arr, bundle_local) {

        //console.log(arr)

        ///renewVisits
        for (let i = 0; i < bundle_local.subjectVisits.length; i++) {

          if (bundle_local.subjectVisits[i].SUBJECTVISITID == bundle_local.vars.SUBJECTVISITID) {
            bundle_local.subjectVisits[i].CIRCLEIMG_VISIT = arr[0].CIRCLEIMG_VISIT;
            break
          }
        }

        //renewForm
        for (let i = 0; i < bundle_local.subjectVisitForms.length; i++) {
          if (bundle_local.subjectVisitForms[i].SUBJECTVISITFORMID == bundle_local.vars.SUBJECTVISITFORMID) {
            bundle_local.subjectVisitForms[i].CIRCLEIMG_FORM = arr[0].CIRCLEIMG_FORM;
            break
          }
        }

        ///renewQuestion
        for (let i = 0; i < bundle_local.subjectQuestions.length; i++) {

          if (bundle_local.subjectQuestions[i].STUDYQUESTIONID == bundle_local.vars.STUDYQUESTIONID) {
            bundle_local.subjectQuestions[i].CIRCLEIMG_QUESTION = arr[0].CIRCLEIMG_QUESTION;
            bundle_local.renewQuestion.emit(bundle_local.vars.STUDYQUESTIONID);
            break
          }
        }


        callback01(inBundle);
      }
    }
  }

  setDocDisplay(display) {
    this.bundle_local.docDisplay = display;
    this.changeDocDisplay.emit()
  }

  getDocDisplay() {
    return this.bundle_local.docDisplay
  }

  NG_LL_infoReq_subjectVisits_pending_get(callback01: Function, inBundle, obj) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectVisits_pending_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;   
    this.bundle_local.vars.QUESTIONSTAGEID = inBundle.activeCODE;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      callback01(arr, inBundle, obj)
    }

  }

  NG_LL_infoReq_subjectVisits_pendingDates_get(callback01: Function, inBundle, obj) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectVisits_pendingDates_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;   

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ////console.log(arr)

      callback01(arr, inBundle, obj)
    }

  }

  NG_LL_infoReq_subjectVisits_pendingValues_get(callback01: Function, inBundle, obj) {


    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectVisits_pendingValues_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      callback01(arr, inBundle, obj)
    }

  }

  NG_LL_infoReq_graph01_elements_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_graph01_elements_get";
    this.bundle_local.vars.LOANMODELID = this.LlModelsService.getActiveId();

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)

      bundle_local.elements_graph01 = arr;


      callback01(arr, inBundle, obj)
    }

  }
  
  setActiveElements_graph01(indx_l, indx_r) {

    this.bundle_local.vars.TARGETELEMENT_LEFT = this.bundle_local.elements_graph01[indx_l].SYSTEMORGANIZATIONFIELDCODE
    this.bundle_local.vars.TARGETELEMENT_RIGHT = this.bundle_local.elements_graph01[indx_r].SYSTEMORGANIZATIONFIELDCODE

  }

  NG_LL_infoReq_graph01_data_get(callback01: Function, inBundle, obj) {

    //this.bundle_local.records.splice(0);

    //var user = this.UserService.getActiveUser();
    //var contactRole = this.RoleListService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_infoReq_graph01_data_get";
    //this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    //this.bundle_local.vars.STUDYID_SYS = contactRole.STUDYID_SYS;
    //this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    //this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    //this.bundle_local.vars.COMPANYID_ACTIVE = this.QuestCompaniesService.getActiveCompanyId();
    //this.bundle_local.vars.TARGETELEMENT_LEFT = "DENT_COLLECTIONS";//inBundle.TARGETELEMENT_LEFT;
    //this.bundle_local.vars.TARGETELEMENT_RIGHT = "DENT_EXPENSES";// inBundle.TARGETELEMENT_RIGHT;

    if (this.bundle_local.vars.TARGETELEMENT_LEFT != "" && this.bundle_local.vars.TARGETELEMENT_RIGHT != "") {
      this.ServerService.callserver(srvReturn, this.bundle_local, "show");
    }

    function srvReturn(arr, bundle_local) {
      //console.log(arr)

      ///bundle_local.records = arr;


      callback01(arr, inBundle, obj)
    }

  }

  NG_LL_infoReq_QC_subjectQuestion_status_edit(ACTION) {

    //console.log(ACTION)
    //console.log("SUBJECTVISITFORMID: "+this.bundle_local.vars.SUBJECTVISITFORMID)
    //console.log("STUDYQUESTIONID: " + this.bundle_local.vars.STUDYQUESTIONID)

    this.bundle_local.vars.ac = "NG_LL_infoReq_QC_subjectQuestion_status_edit"
    this.bundle_local.vars.ACTION = ACTION;
    
    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///renewVisits
      for (let i = 0; i < bundle_local.subjectVisits.length; i++) {

        if (bundle_local.subjectVisits[i].SUBJECTVISITID == bundle_local.vars.SUBJECTVISITID) {
          bundle_local.subjectVisits[i].CIRCLEIMG_VISIT = arr[0].CIRCLEIMG_VISIT;
          //bundle_local.subjectVisits[i].CIRCLEIMG_VISIT = 'assets/statusCircle_query.png';////TESTING

          break
        }
      }

      //renewForm
      for (let i = 0; i < bundle_local.subjectVisitForms.length; i++) {
        if (bundle_local.subjectVisitForms[i].SUBJECTVISITFORMID == bundle_local.vars.SUBJECTVISITFORMID) {
          bundle_local.subjectVisitForms[i].CIRCLEIMG_FORM = arr[0].CIRCLEIMG_FORM;
          //bundle_local.subjectVisitForms[i].CIRCLEIMG_FORM = 'assets/statusCircle_query.png'; ////TESTING
          break
        }
      }

      bundle_local.subjectQuestions = arr;

      ///if the new count on records does not match the old...so some have been added or removed
      if (bundle_local.subjectQuestions.length != arr.length) {

        //console.log('renewForm')

        //renew the entire form
        bundle_local.renewForm.emit();

      } else {

        //console.log('renewQuestion')

        ///renew each question 
        for (let i = 0; i < bundle_local.subjectQuestions.length; i++) {
          bundle_local.renewQuestion.emit(bundle_local.subjectQuestions[i].STUDYQUESTIONID);
        }
      }
    }

  }

  viewQuestionEdit(STUDYQUESTIONID) {
    this.setActiveStudyQuestionId(STUDYQUESTIONID)

    this.viewEditQuestion.emit();
  }
  
  NG_LL_infoReq_subjectQuestion_benchmarkValues_get(callback01: Function, inBundle) {


    this.bundle_local.vars.ac = "NG_LL_infoReq_subjectQuestion_benchmarkValues_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");  

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.benchmarkValues = arr

      var benchmarks: any = []
      var used: any = []

      for (let i = 0; i < arr.length; i++) {
        if (used.indexOf(arr[i].STUDYQUESTIONID) == -1) {
          used.push(arr[i].STUDYQUESTIONID);
          ///benchmarks.push({ STUDYQUESTIONID: arr[i].STUDYQUESTIONID, QUESTIONNAME: arr[i].QUESTIONNAME, QUESTIONNAME: arr[i].QUESTIONNAME });
          benchmarks[benchmarks.length] = arr[i]
        }
      }

      console.log(benchmarks)

   
      callback01(benchmarks, inBundle)

     }
  }

  getSubjectQuetionBenchmarkData(callback01, inBundle, STUDYQUESTIONID, obj) {

    var benchmarks: any = []

    for (let i = 0; i < this.bundle_local.benchmarkValues.length; i++) {
      if (this.bundle_local.benchmarkValues[i].STUDYQUESTIONID == STUDYQUESTIONID) {
        benchmarks[benchmarks.length] = this.bundle_local.benchmarkValues[i]
      }
    }
    callback01(benchmarks, inBundle, obj)

  }
}
